var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.renderComponent
    ? _c(
        "n-page",
        [
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { staticClass: "gx-text-right", attrs: { span: 24 } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openNewTeamModal }
                    },
                    [_vm._v("\n        Create a new Team\n      ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex" } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "gx-card-table-full",
                      attrs: { title: "Teams" }
                    },
                    [_c("club-teams-table")],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("new-team-modal", {
            attrs: { visible: _vm.newTeamModalVisible },
            on: { close: _vm.closeNewTeamModal }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }