var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    staticClass: "gx-table-responsive",
    attrs: {
      columns: _vm.columns,
      "data-source": _vm.teams,
      loading: _vm.teamsLoading
    },
    scopedSlots: _vm._u([
      {
        key: "operations",
        fn: function(text, row) {
          return _c(
            "div",
            { staticClass: "gx-text-right" },
            [
              row.status === "active"
                ? _c(
                    "router-link",
                    {
                      attrs: { to: "/teams/" + row.id },
                      nativeOn: {
                        click: function($event) {
                          return _vm.ManageTeam($event)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "primary", size: "small" } },
                        [_vm._v("\n        Manage Team\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              row.status === "active"
                ? _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.archiveTeam(row.id)
                        }
                      }
                    },
                    [_vm._v("\n      Archive\n    ")]
                  )
                : _vm._e(),
              row.status === "archived"
                ? _c(
                    "a-button",
                    {
                      staticStyle: { "margin-left": "5px" },
                      attrs: { type: "danger", size: "small" },
                      on: {
                        click: function($event) {
                          return _vm.activateTeam(row.id)
                        }
                      }
                    },
                    [_vm._v("\n      Make Active\n    ")]
                  )
                : _vm._e()
            ],
            1
          )
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }