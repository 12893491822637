var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "Create a new Team" },
      on: { cancel: _vm.close, ok: _vm.handleForm },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form, layout: "vertical" } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "Team Name" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "teamName",
                      {
                        rules: [
                          {
                            required: true,
                            message: "The team name is required"
                          }
                        ]
                      }
                    ],
                    expression:
                      "[\n          'teamName',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'The team name is required'\n              }\n            ]\n          }\n        ]"
                  }
                ],
                attrs: { placeholder: "E.G Under 17s Soccer" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }